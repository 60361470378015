$color_1: #2d3576;
$color_2: #0088ce;
$color_3: #26a6e7;
$color_4: #131313;
$color_5: #0071ab;
$color_6: #83be3d;
$color_7: #0f0f0f;
$color_8: #1d1d1d;
$color_9: #fcfcfc;
$color_10: #717171;
$mainColor: $color_2;
$font_1: 'Lato Light';
$font_2: 'Lato Bold';
$font_3: 'Lato Black';
/*----------------------------------------*/

.cd-main-header {
	@media screen and (min-width: 1170px) {
		width: 1170px;
		margin: 0 auto;
	}
}

.main-slide {
	//	margin-top: 10px;
	position: relative; //	top: -10px;

	.headerslide {
		height: 100vh;

		.slide {
			.item {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				height: 100vh;
			}
		}
	}

	.caption-slide {
		@media screen and (min-width: 992px) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		@media screen and (max-width: 991px) {
			position: static;
			background: #000;
			width: 100%;

			.container {
				padding: 0;
			}
		}

		.bg-caption {

			position: relative;
			z-index: 3;
			//			border-top: 10px solid #ea653c;
			padding: 20px;

			@media screen and (min-width: 992px) {
				padding: 60px 50px;
				width: 620px;
				height: 360px;
			}

			background: rgba(0, 0, 0, 0.8);

			h1 {
				display: inline-block;
				position: relative;

				&::before {
					top: -30px;
					left: -30px;
					position: absolute;
					border-top: 3px solid #0088ce;
					border-left: 3px solid #0088ce;
					content: '';
					width: 30px;
					height: 30px;
					display: block;
					margin-bottom: 20px;

					@media screen and (max-width: 767px) {
						top: -20px;
						left: 0;
						position: absolute;
						width: 20px;
						height: 20px;
						display: block;
						margin-bottom: 20px;
					}
				}

				&::after {
					content: '';
					width: 106%;
					display: block;
					border-bottom: 3px solid #F76A3F;
					position: absolute;
					bottom: 59px;
					right: -151px;
					display: inline-block;

					@media screen and (max-width: 767px) {
						border-bottom: 2px solid #F76A3F;
						position: absolute;
						bottom: 54px;
						right: -102px;
						display: inline-block;
					}
				}

				img {
					@media screen and (max-width: 767px) {
						width: 40%;
					}
				}

				span {
					top: -35px;
					font-size: 18px;
					right: -150px;
					color: #fff;

					@media screen and (max-width: 767px) {
						top: -25px;
						font-size: 13px;
						right: -100px;
						color: #fff;
					}
				}
			}

			span {
				font-size: 20px;
				text-transform: uppercase;
				font-weight: 600;
				display: block;
				color: #F76A3F;
				position: relative;
				margin-bottom: 40px;

				//				&::after {
				//					position: absolute;
				//					width: 50px;
				//					content: '';
				//					bottom: -20px;
				//					height: 2px;
				//					left: 0;
				//					border-bottom: 3px solid #fff;
				//				}
			}

			p {
				color: #fff;
			}


		}
	}
}

.recent-project {
	background: #131313;
	position: relative;
	padding: 100px 0;
	top: -5px;
	overflow: hidden;

	h2 {
		@media screen and (max-width: 767px) {
			padding-left: 20px;
		}

		display: block;
		font-size: 60px;
		font-family: "Lato Light";
		color: #fff;
		position: relative;
		padding-bottom: 30px;

		&::before {
			@media screen and (max-width: 767px) {
				left: 10px;
			}

			top: -30px;
			left: -30px;
			position: absolute;
			border-top: 3px solid $color_2;
			border-left: 3px solid $color_2;
			content: '';
			width: 30px;
			height: 30px;
			display: block;
			margin-bottom: 20px;
		}
	}

	.project {
		margin-top: 60px;

		.project-slide {
			.slide-item {
				.item {
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					height: 400px;
				}
			}
		}
	}

	.left_content {
		padding-top: 50px;
	}

	.btn-slide {
		position: absolute;
		right: 60px;
		bottom: -60px;

		button {
			outline: 0;
			background: 0;
			border: 0;
			box-shadow: none !important;
		}
	}

	.all_partner {
		font-family: $font_3;
		text-transform: uppercase;
		color: #fff;
		position: relative;

		&::before {
			content: '';
			width: 30px;
			height: 3px;
			background: $color_2;
			position: absolute;
			bottom: -10px;
			left: 0;
			@include transition();
		}

		&:hover {
			&::before {
				content: '';
				width: 100%;
				@include transition();
			}
		}
	}

	.project-list {
		border-top: 3px solid $mainColor;
	}

	.nav-tabs > li > a {
		color: #797979 !important;
	}

	.nav-tabs > li.active > a,
	.recent-project .nav-tabs > li.active > a:focus,
	.recent-project .nav-tabs > li.active > a:hover {
		color: $mainColor !important;
		border: 0 !important;
	}

	.nav-tabs > li > a {
		border: 0 !important;
	}

	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:focus,
	.nav-tabs > li.active > a:hover {
		color: #555;
		cursor: default;
		background-color: transparent;
		border: 0;
		border-bottom-color: transparent;
	}

	.nav-tabs > li > a:hover {
		border-color: transparent;
	}

	.nav > li > a:focus,
	.nav > li > a:hover {
		text-decoration: none;
		background-color: transparent;
	}

	.nav-tabs {
		border-bottom: 0;
	}

	li.active {
		&::after {
			@media screen and (min-width: 992px) {
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				background: #131313;
				border-right: 4px solid #0088ce;
				border-top: 4px solid #0088ce;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-ms-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
				bottom: -11px;
				left: 50%;
				margin-left: -9px;
			}
		}
	}

	.tab-content > .tab-pane:not(.active),
	.pill-content > .pill-pane:not(.active) {
		display: block;
		height: 0;
		overflow-y: hidden;
	}

	button.slick-prev.slick-arrow {
		position: absolute;
		bottom: -90px;
		right: 150px;
		z-index: 99;
	}

	button.slick-next.slick-arrow {
		position: absolute;
		bottom: -90px;
		right: 100px;
	}
}

.benefits {
	position: relative;
	top: -5px;
	background: $mainColor; //	position: relative;

	.benefits-list {
		color: #fff;

		.benefits-item {
			padding: 100px 20px;
		}

		&.benefit-center {
			background: #26A6E8;
		}
	}

	.benefits-details {
		h2 {
			margin-bottom: 20px;
		}

		padding: 20px 0;
	}
}

/* company-certifications */

.company-certifications {
	padding: 100px 0 300px;
	background-image: url(../images/bg.jpg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom;

	h4 {
		font-size: 16px;
		line-height: 30px;
		text-transform: uppercase;
	}

	.title-section {
		margin-bottom: 50px;

		h2 {
			font-size: 40px;
			display: inline;
			font-family: $font_1;
			text-transform: uppercase;
			position: relative;

			&::before {
				top: -30px;
				left: -30px;
				position: absolute;
				border-top: 3px solid $color_2;
				border-left: 3px solid $color_2;
				content: '';
				width: 30px;
				height: 30px;
				display: block;
				margin-bottom: 20px;
			}
		}
	}

	.certifications-list {
		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: -120px;
			opacity: 0;
			height: 120px;
			background-color: $mainColor;
			-webkit-clip-path: polygon(50% 85%, 0% 100%, 100% 100%);
			clip-path: polygon(50% 85%, 0% 100%, 100% 100%);
			@include transition();
		}

		position: relative;
		min-height: 400px;
		border: 1px solid rgba(204, 204, 204, 0.22);
		@include transition();

		&:hover {
			cursor: pointer;

			&::before {
				opacity: 1;
				@include transition();
			}

			@include transition();
			background: $mainColor;
			color: #fff;

			img {
				filter: invert(100) grayscale(100) brightness(100);
			}
		}

		.certifications-details {
			h3 {
				text-transform: uppercase;
			}

			position: absolute;
			width: 80%;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

/*customer review*/

.customer-review {
	.icon-title {
		position: absolute;
		left: -40px;
		top: -50px;

		@media screen and (max-width: 767px) {
			left: 10px;
			top: -70px;
		}
	}

	color: #fff;
	padding: 100px 0;
	min-height: 300px;
	background-image: url(../images/bg_1-compressor.jpg);
	background-repeat: no-repeat;

	.customer-details {
		margin-top: 30px;

		img {
			width: 100px;
			height: 100px;
			border-radius: 50%;

			@media screen and (min-width: 992px) {
				margin: 0 auto;
			}
		}
	}

	.customer-profile {
		margin-top: 15px;

		p {
			font-size: 26px;
			color: $mainColor;
		}

		span {
			font-style: italic;
		}
	}

	.slick-dots {
		padding: 15px;
		margin-top: 30px;

		li {
			button {
				outline: 0;
				text-indent: -5000px;
				width: 15px;
				height: 3px;
				background: #ccc;
				border: 0;
			}

			padding: 5px;
			display: inline;

			&.slick-active {
				button {
					color: $color_2;
					background: $color_2;
				}
			}
		}
	}
}

/*-- work with the best partner --*/

.work_with_partner {
	padding: 120px 0;

	.logo-partner {
		img {
			margin: 0 auto;
		}
	}

	.left_content {
		h2 {
			@media screen and (max-width: 767px) {
				padding-left: 20px;
			}

			&::before {
				@media screen and (max-width: 767px) {
					left: 10px;
				}

				top: -30px;
				left: -10px;
				position: absolute;
				border-top: 3px solid $color_2;
				border-left: 3px solid $color_2;
				content: '';
				width: 30px;
				height: 30px;
				display: block;
				margin-bottom: 20px;
			}
		}

		.title_section {
			font-family: $font_1;
			font-size: 60px;
			color: $color_4;
			margin-bottom: 30px;
			font-weight: 600;

			span {
				color: #ea653c;
			}
		}

		.all_content {
			p {
				margin-bottom: 30px;
			}
		}

		.all_partner {
			font-family: $font_3;
			text-transform: uppercase;
			color: $color_4;
			position: relative;

			&::before {
				content: '';
				width: 30px;
				height: 3px;
				background: $color_2;
				position: absolute;
				bottom: -10px;
				left: 0;
				@include transition();
			}

			&:hover {
				&::before {
					content: '';
					width: 100%;
					@include transition();
				}
			}
		}
	}
}

/*-- Contact - Homepage --*/

.contact_home {
	background-color: $color_2;
	padding: 60px 0;
	color: #fefefe;

	p {
		font-family: $font_1;
		font-size: 26px;
		text-transform: uppercase;

		span {
			font-family: $font_3;
		}
	}

	.contact_btn {
		text-align: right;
		margin-top: 10px;

		a {
			display: inline-block;
			color: #fefefe;
			background-color: $color_5;
			padding: 15px 30px;
			font-size: 22px;
			border-radius: 4px;
			@include transition();

			&:hover {
				background-color: #fefefe;
				color: $color_5;
				@include transition();
			}
		}
	}
}

/*-- Copy Right --*/

.copyright {
	background-color: $color_8;
	text-align: center;
	color: #ccc;
	padding: 30px 0;

	.copyright_text {
		margin-bottom: 5px;
	}

	.copyright_address {
		margin-bottom: 0;
	}
}

/*-- Footer --*/

.footer {
	background-color: $color_7;

	.footer_box {
		padding: 60px 0;

		hr {
			margin: 30px 0;
			border-top: 1px solid rgba($color_9, 0.3);
		}
	}

	.footer_title {
		font-family: $font_2;
		color: $color_9;
	}

	.all_link {
		a {
			color: rgba($color_9, 0.5);
			margin-bottom: 15px;
			display: block;
			@include transition();

			> span {
				color: rgba($color_9, 0.3);
				font-size: 10px;
				padding-right: 5px;
			}

			&:hover {
				@include transition();
				color: rgba($color_9, 1);
			}
		}
	}

	.middle_content {
		text-align: center;
		text-transform: uppercase;
		font-family: $font_1;
		color: $color_9;
		margin-top: 30px;

		h3 {
			font-size: 22px;
		}
	}

	.newsletter {
		form {
			position: relative;
			overflow: hidden;
		}

		.form-control {
			height: 45px;
			background-color: $color_8;
			border: 1px solid $color_8;
			width: 70%;
			color: #fff;
		}

		.newsletter_btn {
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 0;
			background-color: $color_6;
			border: 0;
			height: 45px;
			padding: 0 15px;
			color: #fff;
			font-family: $font_2;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;

			&::after {
				content: '';
				width: 45px;
				height: 45px;
				background-color: $color_6;
				position: absolute;
				bottom: 0;
				left: -13px;
				transform: rotate(45deg);
				z-index: -1;
			}

			&:focus {
				outline: none;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.contact_home {
		.contact_btn {
			margin-top: 20px;
		}
	}

	.footer {
		.footer_box {
			padding: 60px 0 0 0;
		}

		.newsletter {
			padding-bottom: 60px;
		}
	}
}

@media screen and (max-width: 767px) {
	.contact_home {
		p {
			font-size: 20px;
			text-align: center;
		}

		.contact_btn {
			text-align: center;
			margin-top: 20px;

			a {
				font-size: 18px;
			}
		}
	}

	.copyright {
		font-size: 14px;
	}

	.footer {
		.footer_title {
			font-size: 20px;
		}

		.middle_content {
			margin-top: 0;

			h2 {
				font-size: 18px;
			}
		}

		.newsletter {
			.form-control {
				width: 100%;
			}

			.newsletter_btn {
				padding: 0 10px;
				font-size: 14px;

				&::after {
					display: none;
				}
			}
		}
	}
}

._page_about {
	.title-section {
		h2 {
			&::before {
				display: none;
			}
		}
	}

	.cover-page {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 300px;
		position: relative;

		&::after {
			content: '';
			height: 300px;
			background: rgba(45, 53, 118, 0.7);
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0; //			z-index:1;
		}

		h2 {
			position: relative;
			z-index: 2;
			margin-top: 120px;
			font-size: 40px;
			color: #fff;
			font-family: "Lato Light";
			text-transform: uppercase;
			position: relative;
		}
	}
}

.intro {
	padding: 40px 0;

	h2 {
		color: #2d3576;
		margin-bottom: 20px;
	}

	p {
		line-height: 30px;
	}
}

.present_state {
	padding-bottom: 50px;
	text-align: left;

	h2 {
		color: #2d3576;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 992px) {
	.certificate-logo {
		margin-top: 100px;
	}
}

._download {
	margin: 50px 0 80px;

	.btn-download {
		padding: 20px 70px 20px 30px;
		color: #fff;
		font-weight: 600;
		background: url(../images/pdf.png) 92% 50% no-repeat #0088ce;
		display: inline-block;

		@media screen and (max-width: 991px) {
			display: block;
			margin-top: 20px;
		}

		span {
			font-weight: 100;
			padding-left: 10px;
		}

		&.btn-blue {
			background: url(../images/pdf.png) 92% 50% no-repeat #2d3576;
		}
	}
}

._section_service {
	padding: 50px;
	background: #f2f2f2;
	margin-bottom: 100px;

	h2 {
		margin-bottom: 20px;
		text-transform: uppercase;
		color: #2d3576;
		font-weight: 600;

		span {
			font-weight: 600;
			text-transform: uppercase;
			color: #0088ce;
		}
	}

	h3 {
		margin-top: 30px;
		font-weight: 18px;
		color: #2d3576;
		font-weight: 600;
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 30px;

		span {
			font-weight: 100 !important;
			color: #2d3576;
		}

		&::after {
			content: '';
			position: absolute;
			border-bottom: 2px solid #0088ce;
			width: 50px;
			left: 0;
			bottom: 0;

		}
	}

	ul {
		padding: 0;

		> li {
			color: #000;
			margin-left: 20px;
			padding-left: 10px;
			list-style-image: url(../images/list-style2.png);
		}

	}
}

.content-page {
	padding-top: 50px;
}

.position-bg {
	position: relative;

	@media screen and (max-width: 991px) {
		padding: 50px 0;
	}

	@media screen and (min-width: 1199px) and (max-width: 1560px) {
		right: -100px;
	}

	@media screen and (min-width: 1560px) {
		left: -50px;
	}
}

.border-top {
	position: absolute;
	top: -90px;
	left: 150px;
	z-index: 1;

	@media screen and (max-width: 1199px) {
		display: none;
	}
}

.border-bottom {
	position: absolute;
	bottom: -70px;
	left: -130px;
	z-index: 5;

	@media screen and (max-width: 1199px) {
		display: none;
	}
}
a.btn-download {
    background: #f3632e;
    padding: 10px 20px;
    color: #fff;
}

.certification_list {
	margin-bottom: 20px;
}

._certification_page {

	padding: 0 0 100px 0;
}