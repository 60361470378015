body {
	font-family: 'Lato Regular';
	font-size: 16px;
	color: #717171;
}

h1,
h2,
h3 {
	margin: 0;

}

a {
	&:hover {
		text-decoration: none !important;
	}
}

header {
	position: relative;
}


.gallery_layout {
	.room-item {
		margin-top: 30px;
		text-align: center;
		height: 400px;
		color: #fff;

		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		h3 {
			padding-top: 20px;
		}
		a {
			color: #fff;
		}
		.room-title {
			@include transition(); //				opacity: 0;
			min-height: 150px;
			background: -moz-linear-gradient(top, rgb(45, 53, 118) 0%, rgba(125, 185, 232, 0) 100%);
			background: -webkit-linear-gradient(top, rgb(45, 53, 118) 0%, rgba(125, 185, 232, 0) 100%);
			background: linear-gradient(to bottom, rgb(45, 53, 118) 0%, rgba(125, 185, 232, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84315e', endColorstr='#007db9e8', GradientType=0);
		}
		&:hover {
			.room-title {
				@include transition();
				opacity: 1;
			}
		}
	}
	._no_padding_right {
		padding-right: 0;
	}
	.border_radius {
		border-radius: 0;
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		li {
			list-style: none;
		}
	}
	.big_image {
		height: 415px;
	}
	.small_img {
		height: 200px;
	}
	.bg_cover {
		background-size: cover;
		background-position: center;
		cursor: pointer;
		position: relative;
		margin-bottom: 15px;
	}

	.last_img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(#000, 0.5);
		p {
			span {
				display: inline-block;
				border: 1px solid #fff;
				border-radius: 50%;
				padding: 7px;
			}
			text-align: center;
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	.hover_image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(#000, 0.5);
		opacity: 0;
		@include transition();
		p {
			span {
				display: inline-block;
				border: 1px solid #fff;
				border-radius: 50%;
				padding: 7px;
			}
			text-align: center;
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		&:hover {
			@include transition();
			opacity: 1;
		}
	}

	@media screen and (max-width: 767px) {
		._no_padding_right {
			padding-right: 15px;
		}
		.big_image {
			height: 200px;
		}
	}
}
