
@import 'font-face';
@import 'mixins';
@import 'variable';
@import 'default';
@import 'helper';
@import 'navbar';
@import 'main';
@import 'header-slide';


$darkbrown: #482400;
$eee: #eee;
$BoldFont: 'Roboto Bold',
'Prompt Bold';
$BoldFont2: 'Roboto Regular',
'Prompt Regular';
$brown: #BE9337;
$RegularFont: 'Roboto Regular',
'Prompt Regular';
$Gray: #5A5657;
$fourth_font: 'Roboto Regular',
'Prompt Regular';

$main_color: $mainColor;
.title_page_section {
	position: relative;
	margin-top: 160px;
	text-align: center;
	margin-bottom: 60px;
	h1 {
		padding: 0px 0 10px;
		color: #74903B;
		display: inline-block;
		border-bottom: 1px solid #7c6f4f;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 40px;
			border-bottom: 3px solid #74903B;
			bottom: -1px;
			right: 0;
			margin: 0 auto;
		}
	}
}


.contact_page {

	//	background: #d3d5bf;
	.contact_us {
		margin-top: 50px;
		@media screen and (min-width: 992px) {
			margin-top: 200px;
		}
		position: relative;
		.contact_detail {
			padding: 60px;
			color: $darkbrown;
			min-height: 400px;

			background-color: $eee;
			p,
			a {
				color: $darkbrown;
			}

			.title_contact {
				h2 {
					font-size: 40px;
					font-weight: 600;
					color: #404040;
				}
				.company_name {
					font-size: 18px;
					font-family: $BoldFont;
					margin-bottom: 15px;
				}
			}
			.item_contact {
				margin-bottom: 10px;
				span {
					font-family: $BoldFont;
				}
			}
			.social_media {
				p {
					font-family: $BoldFont;
					margin-bottom: 10px;
				}
				.icon_media {
					background-color: $darkbrown;
					width: 32px;
					height: 32px;
					border-radius: 50%;
					display: inline-block;
					text-align: center;
					@include transition();
					i {
						color: #fff;
						line-height: 32px;
						font-size: 20px;

					}
					&:hover {
						background-color: $brown;
						@include transition();
					}
				}
				ul {
					padding-left: 0;
					margin-bottom: 0;
					li {
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
		}
		hr {
			margin-top: 30px;
			margin-bottom: 30px;
			border-top: 1px solid rgba($brown, 0.3);
		}
	}
}

.contact_form {
	//	margin: 100px 0;
	//	padding: 60px;
	//	border: 1px solid #ccc;
	background: #fff;
	.contact_title {
		h3 {
			font-family: $fourth_font;
			text-transform: uppercase;
			color: $main_color;
		}
	}
	.btn_submit {
		margin: 30px 0;
		text-align: center;
		input {

			border-radius: 15px;
			@include transition();
			padding: 10px 30px;
			display: inline-block;
			position: relative;
			z-index: 1;
			text-transform: uppercase;
			font-family: $fourth_font;
			color: #fff;
			background-color: $main_color;
			border: 1px solid $main_color;
			&:hover {
				color: $main_color;
				background: #fff;
				border: 1px solid $main_color;
			}
		}
	}

	.input-container {
		position: relative;
		input {
			border: 1px solid rgba(132, 49, 94, 0.3) !important;
			border-radius: 5px !important;
			background-color: #fff;
			margin-top: 30px;

			outline: none;
			transition: .2s ease-in-out;
			box-sizing: border-box;
		}

		textarea {
			border: 1px solid rgba(132, 49, 94, 0.3) !important;
			background-color: #fff;
			margin-top: 30px;
		
			outline: none;
			transition: .2s ease-in-out;
			box-sizing: border-box;
		}

		input {
			padding: 15px;
			width: 100%;
			height: 45px;
		}
		textarea {
			padding: 15px;
			width: 100%;
		}

	}
}

@media screen and (min-width: 992px) {

	.contact_page {
		position: relative;
		.custom_column {
			position: absolute;
			right: -15px;
			bottom: -50px;

			iframe {
				box-shadow: 0px 0px 20px rgba($Gray, 0.5);
				width: 100%;
			}
		}
	}

}

@media screen and (max-width: 991px) {
	.contact_page {
		position: relative;
		.custom_column {

			iframe {
				width: 100%;
			}
		}
	}

}

@media screen and (max-width: 475px) {

	.contact_form {
		padding: 30px;
	}
}
