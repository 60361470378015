$mainColor: #2d3576;
.main_menu {
    box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.4);
	position: relative;
	z-index: 2;
	.top_bar {
		background: $mainColor;
		padding: 10px 0;
		color: white;
		font-weight: 300;

		.follow_us {
			position: relative;

		}
		p {
			margin: 0;
			line-height: 20px;
			@media screen and (max-width: 991px) {
				font-size: 13px;
			}
			.fa-facebook-square {
				font-size: 20px;
				top: 2px;
				margin-left: 4px;
				color: #3f51b5;
				background: #fff;
				padding: 0;
				line-height: 15px;
				width: 16px !important;
				right: 0;
				position: relative;
				@media screen and (max-width: 767px) {
					width: 17px;
				}
			}
		}
	}
	.menu_bar {
		.navbar {
			margin: 0;
			border: 0 !important;
			@media screen and (min-width: 1200px) {
				padding-left: 100px;
			}
			.navbar-header {
				@media screen and (max-width: 991px) {
					padding: 10px 0;
				}
			}
			.btn_login {
@media screen and (max-width: 767px)
{
	display: none;
				}
				@media screen and (min-width: 992px) and (max-width: 1199px) {
					right: 0
				}
				@media screen and (max-width: 991px) {
					position: absolute;
					top: -35px;
					left: inherit;
					right: 190px;
					span {
						font-size: 14px;
						margin-left: 5px;
					}

					a {
						border-radius: 0;
						box-shadow: none;
						
					}
				}
				position: absolute;
				right: 0;
				top: 15px;
				a {
					width: 30px;
					height: 30px;
					display: inline-block;
					background: #000;
					text-align: center;
					line-height: 30px;
					border-radius: 50%;
					color: #fff;

					&.cls-facebook {
						background-color: #5073A7;
					}
					&.cls-youtube {
						background-color: #C43435;
					}
					&.cls-ig {
						background-color: #444444;
					}
				}
			}

			li {
				@media screen and (max-width: 991px) {
					border-bottom: 1px solid rgba( $mainColor, 0.2);
				}
				a {
					padding: 20px 10px;
				}
				&:active {
					a {
						color: $mainColor;
						background: none;
						font-weight: 600;
					}
				}
			}
			li.active {
				a {
					color: $mainColor !important;
					background: none;
					font-weight: 600;
				}
			}
			a {
				text-transform: uppercase;
			}
		}
		.navbar-collapse {
			@media screen and (max-width: 991px) {
				padding: 0;
			}
		}
		.navbar-brand {
			padding: 0 15px;
			height: inherit !important;
			img {
				max-height: 60px;
			}
			@media screen and (max-width: 767px) {
				margin-top: 10px;
				max-width: 200px;
			}
		}
		.navbar-default {
			.container {
				position: relative;
			}
			padding: 10px 0;
			@media screen and (max-width: 991px) {
				padding: 0 !important;
			}
			background: white;
			border-color: white;
		}
	}
}
