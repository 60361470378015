@font-face {
    font-family: 'Lato Light';
    src: url('../fonts/Lato-Light.woff2') format('woff2'),
        url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Black';
    src: url('../fonts/Lato-Black.woff2') format('woff2'),
        url('../fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Regular';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Italic';
    src: url('../fonts/Lato-Italic.woff2') format('woff2'),
        url('../fonts/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../fonts/Lato-Hairline.woff2') format('woff2'),
        url('../fonts/Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

