$btn_color : #37868f;

.header-slide {
    overflow: hidden;
    position: relative;
    .slide {
        .item {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 100vh;
            @media screen and (max-width: 1199px) {
                height: 450px;
            }
            @media screen and (max-width: 991px) {
                height: 400px;
            }
            @media screen and (max-width: 767px) {
                height: 350px;
            }
            @media screen and (max-width: 480px) {
                height: 250px;
            }
        }
    }
    .btn-slide {
			display: none;
        button {
		
            width: 50px;
            height: 50px;
            background: $btn_color;
            padding: 8px 10px;
            color: #fff;
            border: none !important;
            outline: none !important;
            &._prev {
                position: absolute;
                left: 0;
                top: 48%;
            }
            &._next {
                position: absolute;
                right: 0;
                top: 48%;
            }
        }
    }
}
